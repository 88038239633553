import React from 'react';
import mainLogo from '../../assets/images/ringsavvy-logo.svg';
import reverseLogo from '../../assets/images/ringsavvy-logo_reverse.svg';
import whiteLogo from '../../assets/images/ringsavvy-logo_white.svg';
import mainLogoTagline from '../../assets/images/RingSavvyTagline-WhiteGreen.svg';
import whiteLogoTagline from '../../assets/images/RingSavvyTagline-White.svg'
import reverseLogoTagline from '../../assets/images/RingSavvyTagline-BlackGreen.svg'
const Logo = ({ isWhite, isReverse }) => {
  let logo;

  if (isWhite) {
    logo = whiteLogoTagline;
  } else if (isReverse) {
    logo = reverseLogoTagline;
  } else {
    logo = mainLogoTagline;
  }

  return <img src={logo} alt='Ring Savvy' />;
};

export default Logo;
